import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import classNames from 'classnames';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { useHistory, useLocation } from 'react-router-dom';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
  LISTING_STATE_PUBLISHED,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureTransaction,
  ensureUser,
  getPageURL,
  userDisplayNameAsString,
} from '../../util/data';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes.js';
// import { richText } from '../../util/richText';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  ResponsiveImage,
  Modal,
  SocialShare,
  IconShare,
  IconCard,
  Button,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  // setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  createResumePDF,
  requestInsertCollection,
  checkPendingDeduction,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  // listingImages,
  // handleContactUser,
  // handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';

// import { facebookImageURL, twitterImageURL } from '../../config/configBranding';
import share from '../../assets/images/share.png';
import listView from '../../assets/images/listView.png';

import SectionPro from './SectionPro';
import ActionBarMaybe from './ActionBarMaybe';
import SectionCompany from './SectionCompany';
import ChatForm from './ChatForm/ChatForm';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck.js';

import css from './ListingPage.module.css';
import { getProcess } from '../../transactions/transaction.js';
import { confirmPayment, initiateOrder, sendMessage } from '../CheckoutPage/CheckoutPage.duck.js';
import { P } from '../PageBuilder/Primitives/P/P.js';
import { closeListing, openListing } from '../ManageListingsPage/ManageListingsPage.duck.js';

// const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [showModal, setShowModal] = useState(false);
  const [modalInProgress, setModalInProgress] = useState(false);
  const [currentTab, setCurrentTab] = useState(false);
  const [copyText, setCopyText] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [firstConnectionModal, setFirstConnectionModal] = useState(false);
  const [noToken, setNoToken] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setCopyText(false);
    }, 5000);
  }, [copyText]);
  // const [inquiryModalOpen, setInquiryModalOpen] = useState(
  //   props.inquiryModalOpenForListingId === props.params.id
  // );

  const {
    isAuthenticated,
    currentUser,
    currentUserListings,
    authorListings,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    createResumeInProgress,
    onCreateResumePDF,
    onInsertCollection,
    onUpdateProfile,
    onInitiateOrder,
    onConfirmPayment,
    onSendMessage,
    showPortfolio,
    onCloseListing,
    onOpenListing,
    onCheckPendingDeduction
  } = props;

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const publishedListing = currentUserListings && currentUserListings.length
    ? currentUserListings.filter(c => c.attributes.state == LISTING_STATE_PUBLISHED)
    : [];
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const topbar = <TopbarContainer />;
  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
  } = (currentListing.id && currentListing.attributes) || {};
  const [tokensShow, SettokenShow] = useState(0);
  const onTotalTokensChange = (e) => {
    SettokenShow(e)
  }

  const {
    educations = [],
    experiences = [],
    portfolio = [],
    skills = [],
    q_as,
    addSelfie1,
    addSelfie2,
    uploadVideoInPhotoes,
    ADMIN,
  } = publicData;
  const [visible, setVisible] = useState(false);

  const filterTabs = [];
  if (publicData) {
    if (description && description.length > 0) {
      filterTabs.push("Bio");
    }
    if (skills && skills.length) {
      filterTabs.push("Skills");
    }
    if (reviews && reviews.length) {
      filterTabs.push("Reviews");
    }
    if (portfolio && portfolio.length) {
      filterTabs.push("Portfolio");
    }
    if (experiences && experiences.length) {
      filterTabs.push("History");
    }
    if (q_as && q_as.length) {
      filterTabs.push("Q&A");
    }
    if (addSelfie1 && addSelfie2) {
      filterTabs.push("Personal");
    }
    if (educations && educations.length) {
      filterTabs.push("Education");
    }
  }

  const { protectedData: uProtectedData, publicData: uPublicData } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
  const { currentSeeds = 0, CurrentTokens = 0, firstConnectionRequest = false } = uProtectedData || {};
  const CurrentSeeds = currentSeeds || CurrentTokens;
  const { accountType: cAccountType } = uPublicData || {};

  useEffect(() => {
    if (cAccountType == "company" && !firstConnectionRequest && !firstConnectionModal) {
      setFirstConnectionModal('CONNECTION');
    }
  }, [cAccountType]);

  useEffect(() => {
    let tabsOffsetPosition = [];

    if (currentTab) {
      filterTabs.forEach(tab => {
        const element = document.getElementById(tab);
        const offset = 55;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element && element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
        tabsOffsetPosition.push({
          tab,
          offsetPosition
        });
      });
    }

    const scrollHandler = () => {
      if (typeof window !== 'undefined') {
        for (let i = 0; i < tabsOffsetPosition.length; i++) {
          const op = tabsOffsetPosition[i];
          if (i == 0) {
            if (parseInt(window.scrollY) < parseInt(tabsOffsetPosition[1].offsetPosition - 55)) {
              setCurrentTab(op.tab);
              break;
            }
          } else if (i == (tabsOffsetPosition.length - 1)) {
            if (parseInt(window.scrollY) > parseInt(tabsOffsetPosition[1].offsetPosition)) {
              setCurrentTab(op.tab);
              break;
            }
          } else if (parseInt(window.scrollY) >= parseInt(op.offsetPosition - 65) && parseInt(window.scrollY) <= parseInt(tabsOffsetPosition[i + 1].offsetPosition - 65)) {
            setCurrentTab(op.tab);
            break;
          }
        }

        if (window.pageYOffset > 596) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    };

    if (typeof window !== 'undefined' && currentTab) {
      window.addEventListener('scroll', scrollHandler);
    }

    return () => {
      if (typeof window !== 'undefined' && currentTab) {
        window.removeEventListener('scroll', scrollHandler);
      }
    }
  }, [currentTab]);

  if (ADMIN) {
    return <NamedRedirect name={"PurchaseAcornsPage"} />
  } else if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const richTitle = (title
    ? <span>
      {title.split(' ')[0]}
    </span>
    : null
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
  const { availableHoursPerWeek } = ensuredAuthor.attributes.profile.publicData || {};
  const { companyName, accountType } = (ensuredAuthor && ensuredAuthor.id && ensuredAuthor.attributes.profile.publicData) || {};
  const isCompany = accountType ? accountType == 'company' : true;
  const listingTab = isDraftVariant ? 'photos' : isCompany ? 'requirements' : 'details';
  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = (typeof uploadVideoInPhotoes == 'string' || typeof addSelfie1 == 'string' || typeof addSelfie2 == 'string')
    ? [uploadVideoInPhotoes
      ? uploadVideoInPhotoes.replace(/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i, '.png')
      : (addSelfie1 || addSelfie2)]
    : []; //listingImages(currentListing, 'facebook');
  const twitterImages = (typeof uploadVideoInPhotoes == 'string' || typeof addSelfie1 == 'string' || typeof addSelfie2 == 'string')
    ? [uploadVideoInPhotoes
      ? uploadVideoInPhotoes.replace(/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i, '.png')
      : (addSelfie1 || addSelfie2)]
    : []; //listingImages(currentListing, 'twitter');
  const schemaImages = (typeof uploadVideoInPhotoes == 'string' || typeof addSelfie1 == 'string' || typeof addSelfie2 == 'string')
    ? [uploadVideoInPhotoes
      ? uploadVideoInPhotoes.replace(/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i, '.png')
      : (addSelfie1 || addSelfie2)]
    : [];
  // listingImages(
  //   currentListing,
  //   `${config.layout.listingImage.variantPrefix}-2x`
  // ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title: isCompany ? companyName : title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  // const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  const printDocument = () => {
    onCreateResumePDF({
      listingId: rawParams.id,
      userName: currentUser.attributes.profile.firstName + ' ' + currentUser.attributes.profile.lastName
    }).then(res => window.open(res.cloudinaryUrl, '_blank'));
  }

  const handleSubmitModal = async (values) => {
    const {
      showModal,
      type,
      thoughts = '',
      email = '',
      ...rest
    } = values;
    if (showModal == "Notes") {
      setModalInProgress(type);
      if (type == 'SEND') {
        // SendGrid Email to particular user
        onInsertCollection({
          to: {
            email,
            name: ''
          },
          thoughts,
          profileUrl: process.env.REACT_APP_MARKETPLACE_ROOT_URL + ('/l/' + listingSlug + '/' + listingId.uuid),
          listingId: listingId.uuid,
          createdAt: moment().toDate(),
          ...rest
        }, type).then(() => {
          setShowModal(false);
          setCopyText('Email_Sent');
          setModalInProgress(false);
        });
      } else {
        onInsertCollection({
          tableName: 'notes',
          payload: {
            listingId: listingId.uuid,
            userId: currentUser.id.uuid,
            thoughts,
            createdAt: moment().toDate(),
            isActive: '1',
            ...rest
          }
        }).then(() => {
          setShowModal(false);
          setCopyText('Saved_Notes');
          setModalInProgress(false);
        });
      }
    } else {
      setModalInProgress('SUBMIT');
      const listingId = new UUID(params.id);
      // onInsertCollection({
      //   tableName: 'connections',
      //   payload: {
      //     listingId: listingId.uuid,
      //     userId: currentUser.id.uuid,
      //     authorId: ensuredAuthor.id.uuid,
      //     createdAt: moment().toDate(),
      //     isActive: '1',
      //     ...rest
      //   }
      // }).then(() => {
      //   mixpanel.track('Connection', {
      //     user_id: currentUser.id.uuid,
      //     recipientEmail: ensuredAuthor.attributes.email,
      //     requesterEmail: currentUser.attributes.email,
      //     hiringRole: rest.role,
      //     companyName: currentUser.attributes.profile.publicData.companyName,
      //     environment: process.env.REACT_APP_ENV,
      //   });

      const roleIndex = publishedListing.findIndex(pl => pl.id.uuid == rest.role);
      const orderParams = {};
      let totalSeeds;
      // C-Level -15, Director -10 or Manager/ Specialist -5 tokens are added back to their balance
      if (roleIndex > -1) {
        const { roles = [], highestExperience } = (publishedListing[roleIndex] && publishedListing[roleIndex].id && publishedListing[roleIndex].attributes.publicData) || {};
        totalSeeds = highestExperience && highestExperience.tokens
          ? highestExperience.tokens
          : roles && roles.length
            ? roles.reduce((accu, curr) => (curr.tokens || 0) + accu, 0)
            : 5;
        let checkPoints = await onCheckPendingDeduction(totalSeeds);
        if (!checkPoints) {
          setNoToken(true);
          setModalInProgress(false);
          return null
        }
        const initialValues = {
          protectedData: {
            listingId: publishedListing[roleIndex].id.uuid,
            hiringRole: rest.role,
            hiringMessage: rest.bio,
            bookingType: 'CONNECTION',
            hoursPerWeek: rest.hoursPerWeek,
            hourlyPrice: { amount: rest.hourlyPrice.amount, currency: rest.hourlyPrice.currency },

            type: "DEBIT",
            RNT_TOKEN_ID: 'connection',
            tokens: totalSeeds,
          },
          confirmPaymentError: null,
        };
        setNoToken(false)
        if (!firstConnectionRequest) {
          Object.assign(initialValues.protectedData, {
            firstConnectionRequest: true
          });
        }
        Object.assign(orderParams, {
          listingId,
          quantity: 1,
          needToUpdateWallet: true,
          type: "DEBIT",
          firstConnectionRequest,
          RNT_TOKEN_ID: 'connection',
          tokens: totalSeeds,
          ...initialValues,
        });
      }
      const processAlias = config.listing.listingTypes[0].transactionType.process;
      const process = getProcess(processAlias);

      // Step 1: initiate order by requesting payment from Marketplace API
      const fnRequestPayment = fnParams => {
        // fnParams should be { listingId, deliveryMethod, quantity?, bookingDates?, paymentMethod?.setupPaymentMethodForSaving?, protectedData }
        const requestTransition = process.transitions.REQUEST_PAYMENT;
        const isPrivileged = process.isPrivileged(requestTransition);

        // If paymentIntent exists, order has been initiated previously.
        return onInitiateOrder(fnParams, (processAlias + '/release-1'), null, requestTransition, isPrivileged);
      };

      // Step 2: complete order by confirming payment to Marketplace API
      // Parameter should contain { paymentIntent, transactionId } returned in step 2
      const fnConfirmPayment = fnParams => {
        const order = ensureTransaction(fnParams);
        const transitionName = process.transitions.CONFIRM_PAYMENT;
        return onConfirmPayment({
          ...fnParams,
          transactionId: order.id,
          ...orderParams,
        }, transitionName, {});
      };

      // Step 3: send initial message
      const fnSendMessage = fnParams => {
        return onSendMessage({ ...fnParams, message: rest.bio });
      };


      // Here we create promise calls in sequence
      // This is pretty much the same as:
      const applyAsync = (acc, val) => acc.then(val);
      const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
      const handlePaymentIntentCreation = composeAsync(
        fnRequestPayment,
        fnConfirmPayment,
        fnSendMessage,
      );

      // C-Level -15, Director -10 or Manager/ Specialist -5 tokens are added back to their balance
      if (roleIndex > -1 && ((CurrentSeeds > totalSeeds) || !firstConnectionRequest)) {
        return handlePaymentIntentCreation(orderParams)
          .then((res) => {
            mixpanel.track('SeedSpend', {
              distinct_id: currentUser.id.uuid,
              user_id: currentUser.id.uuid,
              email: currentUser.attributes.email,
              ...rest,
              tokens: totalSeeds,
              environment: (process.env && process.env.REACT_APP_ENV) || 'development',
            });
            setShowModal(false);
            setModalInProgress(false);
            return history.push(
              createResourceLocatorString(
                'OrderDetailsPage',
                routeConfiguration,
                {
                  id: res.orderId.uuid, // 'InboxPage'
                },
                {}
              ));
          });
      }
      setShowModal(false);
      setFirstConnectionModal('UNSUFFICIENT');
    }
  }

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={isCompany
        ? ("Find " + companyName + " on Forteh - Hiring a Fractional Professional for " + (authorListings && authorListings.length ? authorListings[0].attributes.title : '') + " for " + (authorListings && authorListings.length ? '$' + (authorListings[0].attributes.price.amount / 100) : '') + ".")
        : description}
      // facebookImages={isCompany ? [{
      //   height: 630,
      //   name: "facebook",
      //   url: facebookImageURL,
      //   width: 1200
      // }] : facebookImages}
      // twitterImages={isCompany ? [{
      //   height: 314,
      //   name: "twitter",
      //   url: twitterImageURL,
      //   width: 600
      // }] : twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        {currentListing.id ? (
          <ActionBarMaybe
            className={css.actionBarForProductLayout}
            isOwnListing={isOwnListing}
            listing={currentListing}
            isCompany={isCompany}
            editParams={{
              id: listingId.uuid,
              slug: listingSlug,
              type: listingPathParamType,
              tab: listingTab,
            }}
            closeListing={onCloseListing}
            openListing={onOpenListing}
          />
        ) : null}
        {isCompany
          ? <SectionCompany
            intl={intl}
            config={config}
            listingId={listingId}
            listingSlug={listingSlug}
            listingConfig={listingConfig}
            reviews={reviews}
            history={history}
            share={share}
            listView={listView}
            richTitle={richTitle}
            currentTab={currentTab}
            currentUser={currentUser}
            onUpdateProfile={onUpdateProfile}
            currentListing={currentListing}
            setCurrentTab={setCurrentTab}
            setShowModal={setShowModal}
            filterTabs={filterTabs}
            isOwnListing={isOwnListing}
            fetchReviewsError={fetchReviewsError}
            isAuthenticated={isAuthenticated}
            authorListings={authorListings}
            currentUserListings={currentUserListings}
            onManageDisableScrolling={onManageDisableScrolling}
          />
          : <SectionPro
            intl={intl}
            config={config}
            isAuthenticated={isAuthenticated}
            listingConfig={listingConfig}
            reviews={reviews}
            history={history}
            share={share}
            listView={listView}
            richTitle={richTitle}
            currentTab={currentTab}
            currentUser={currentUser}
            currentListing={currentListing}
            setCurrentTab={setCurrentTab}
            setShowModal={setShowModal}
            filterTabs={filterTabs}
            currentUserListings={currentUserListings}
            onManageDisableScrolling={onManageDisableScrolling}
            showPortfolio={showPortfolio}
            isOwnListing={isOwnListing}
            onTotalTokensChange={tokensShow}
          />}

        {['Email_Sent', 'Saved_Notes'].includes(copyText)
          ? <div className={classNames(css.textCopiedPopup, copyText ? css.popUpEntrada : css.popUpSaids)} onClick={() => setCopyText(false)} >
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" >
              <ellipse cx="10" cy="11" rx="10" ry="10.5" fill="#A9E7B7" />
              <path d="M15.1583 7.63492L8.01225 14.3049L5.85325 12.1469C5.65825 11.9519 5.34125 11.9519 5.14625 12.1469C4.95125 12.3419 4.95125 12.6589 5.14625 12.8539L7.64625 15.3539C7.74325 15.4509 7.87125 15.4999 7.99925 15.4999C8.12225 15.4999 8.24425 15.4559 8.34025 15.3659L15.8403 8.36592C16.0423 8.17792 16.0523 7.86092 15.8643 7.65892C15.6763 7.45692 15.3613 7.44592 15.1583 7.63492Z" stroke="#00A524" />
            </svg>
            <p>
              {['Saved_Notes'].includes(copyText)
                ? <FormattedMessage id={"ListingPageComponent.savedNotesLink"} />
                : <FormattedMessage id={"ListingPageComponent.emailSentLink"} />}
            </p>
            <span className={css.close}>
              <IconShare type='copy' />
            </span>
          </div>
          : null}

        <Modal
          id="ListingPage.MultiPurposeModal"
          isOpen={!!showModal}
          onClose={() => setShowModal(false)}
          usePortal
          type={'MultiPurposeModal'}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {showModal == 'Request'
            ? null
            : <div className={css.logoImage}>
              {uploadVideoInPhotoes
                ? <div className={classNames(css.videoBox, showPortfolio ? null : css.disableOverlay)} >
                  <video width="170" height="170" controls muted  >
                    <source alt={"video"} src={uploadVideoInPhotoes} type="video/mp4" />
                  </video></div> : <ResponsiveImage
                  className={classNames(css.avtarLogo, showPortfolio ? null : css.disableOverlay)}
                  alt='display image'
                  url={uploadVideoInPhotoes
                    ? (uploadVideoInPhotoes.replace(/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i, '.png'))
                    : addSelfie1}
                  showModal
                  image={currentListing.images[0]}
                  variants={['default']}
                />
              }
              <div className={uploadVideoInPhotoes ? css.videoIcon : css.NoVideoIcon} onClick={() => setVideoPlay(true)}>
                <svg fill="#000000" width="40px" height="40px" viewBox="0 -0.4 26.4 26.4" xmlns="http://www.w3.org/2000/svg"><title>video</title><path d="M13.2 22.8q-2.65 0 -5 -1.35 -2.3 -1.35 -3.65 -3.65T3.2 12.8t1.35 -5 3.65 -3.65 5 -1.35 5 1.35 3.65 3.65 1.35 5q0 2.65 -1.35 5 -1.35 2.3 -3.65 3.65t-5 1.35m4.05 -10 -6.45 -4.8v9.6z" /></svg>
              </div>
            </div>}

          {showModal == 'Share'
            ? <div>
              <SocialShare
                isPlain
                url={getPageURL()}
                createResumeInProgress={createResumeInProgress}
                printDocument={printDocument}
                PageName={'ListingPage'}
                onClose={() => { }}
                isAuthenticated={isAuthenticated}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            </div>
            : <div>
              <ChatForm
                intl={intl}
                name={title}
                config={config}
                showModal={showModal}
                inProgress={modalInProgress}
                onSubmit={handleSubmitModal}
                currentUserListings={publishedListing}
                onTotalTokensChange={(e) => { onTotalTokensChange(e) }}
                showPortfolio={showPortfolio}
                noToken={noToken}
              />
            </div>}
        </Modal>
        <Modal
          id="ListingPage.FirstConnectionModal"
          isOpen={!!firstConnectionModal}
          onClose={() => setFirstConnectionModal(false)}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
          className={css.firstConnectionPopup}
        >
          {firstConnectionModal == 'CONNECTION'
            ? <h4 className={css.popupContent}>
              <IconCard brand="IconConnection" />
              <FormattedMessage id={"ListingPage.modalFirstConnectionText"} />
            </h4>
            : <div>
              <h4 className={css.popupContent}>
                <FormattedMessage id={"ListingPage.unsufficientTokenMsg"} />
              </h4>
              <Button
                onClick={() => history.push(createResourceLocatorString('PurchaseAcornsPage', routeConfiguration, {}), {
                  from: `${location.pathname}${location.search}${location.hash}`
                })}
              >
                <FormattedMessage id={"ListingPage.unsufficientTokenButtonMsg"} />
              </Button>
            </div>}
        </Modal>
      </LayoutSingleColumn>
    </Page >
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    authorListingIds,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    createResumeInProgress,
    showPortfolio
  } = state.ListingPage;
  const { currentUser, currentUserListings } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const authorListings = getListingsById(state, authorListingIds);

  return {
    isAuthenticated,
    currentUser,
    authorListings,
    currentUserListings,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    createResumeInProgress,
    showPortfolio
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onCreateResumePDF: (payload) => dispatch(createResumePDF(payload)),
  onInsertCollection: (payload, type) => dispatch(requestInsertCollection(payload, type)),
  onUpdateProfile: (payload) => dispatch(updateProfile(payload)),
  onInitiateOrder: (params, processAlias, transactionId, transitionName, isPrivileged) =>
    dispatch(initiateOrder(params, processAlias, transactionId, transitionName, isPrivileged)),
  onConfirmPayment: (orderParams, transitionName, transitionParams) =>
    dispatch(confirmPayment(orderParams, transitionName, transitionParams)),
  onCheckPendingDeduction: (payload) =>
    dispatch(checkPendingDeduction(payload)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
